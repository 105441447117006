export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [0,5,6,9,8,11,14];

export const dictionary = {
		"/(auth)/callbacks/facebook": [~15,[2,3]],
		"/(auth)/callbacks/google": [~16,[2,3]],
		"/(auth)/create-profile": [~17,[2]],
		"/(protected)/dashboard": [~22,[4,5]],
		"/(protected)/dashboard/account": [23,[4,5]],
		"/(protected)/dashboard/account/billing": [24,[4,5]],
		"/(protected)/dashboard/account/profile": [25,[4,5]],
		"/(protected)/dashboard/account/profile/notifications": [26,[4,5]],
		"/(protected)/dashboard/account/profile/security": [27,[4,5]],
		"/(protected)/dashboard/account/subscriptions": [~28,[4,5]],
		"/(protected)/dashboard/account/team": [~29,[4,5]],
		"/(protected)/dashboard/analytics": [~30,[4,5]],
		"/(protected)/dashboard/leads": [~31,[4,5]],
		"/(protected)/dashboard/marketing": [32,[4,5,6]],
		"/(protected)/dashboard/marketing/[generateCampaignStep]": [~33,[4,5,6]],
		"/(protected)/dashboard/websites": [34,[4,5]],
		"/(protected)/dashboard/websites/domains": [~35,[4,5]],
		"/(protected)/dashboard/websites/seo": [36,[4,5]],
		"/(auth)/email-confirmation": [~18,[2]],
		"/flow": [~48],
		"/flow/01/1": [~49,[9]],
		"/flow/01/2": [~50,[9]],
		"/(auth)/login": [~19,[2]],
		"/(protected)/lp/edit": [~37,[4,7]],
		"/(protected)/lp/edit/preview": [38,[4,7]],
		"/maintenance": [~51],
		"/(protected)/marketing/create": [39,[4,8]],
		"/(protected)/marketing/create/budget": [40,[4,8]],
		"/(protected)/marketing/create/discount": [41,[4,8]],
		"/(protected)/marketing/create/email": [42,[4,8]],
		"/(protected)/marketing/create/location": [43,[4,8]],
		"/(protected)/marketing/create/phone": [44,[4,8]],
		"/(protected)/marketing/create/preview": [45,[4,8]],
		"/(protected)/marketing/create/service": [46,[4,8]],
		"/onboard": [~52],
		"/onboard/preview": [~53,[10]],
		"/onboard/preview/[lpKey]": [~54,[10]],
		"/(protected)/payment/inprogress": [47,[4]],
		"/(auth)/recover-password": [~20,[2]],
		"/(auth)/reset-password": [~21,[2]],
		"/website-builder": [55,[11]],
		"/website-builder/(inprogress)/inprogress": [56,[12]],
		"/website-builder/(register)/register": [~57,[13]],
		"/website-source": [58,[14]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';